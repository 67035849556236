<template>
  <div>
    <b-form>
      <b-card class="card-congratulations">
        <h1 class="text-white">Proposta: {{ parametro.codigo }}</h1>
        <b-card-text>
          <b-row>
            <b-col cols="21" xl="6" class="d-flex justify-content-between flex-column">
              <div class="d-flex justify-content-start">
                <b-avatar
                  :src="parametro.foto != null ? parametro.foto.foto : null"
                  :text="avatarText(parametro.segurado.tomador.nomeCompleto)"
                  size="104px"
                  rounded
                />
                <div class="d-flex flex-column ml-1">
                  <div class="mb-1">
                    <h4 class="mb-0" style="color: white">
                      {{ parametro.segurado.tomador.nomeCompleto }}
                    </h4>
                    <span class="card-text">CPF: {{ parametro.segurado.tomador.cpf }}</span
                    ><br />
                    <span class="card-text"
                      >{{ parametro.origem }}:
                      <span class="badge badge-light-alert badge-pill">{{
                        this.formatarCodigoSequencial(parametro.codigo)
                      }}</span></span
                    ><br />
                    <span v-if="vendedor" class="card-text"
                      >Vendedor: <span class="font-weight-bold"> {{ vendedor }} </span></span
                    >
                  </div>
                </div>
              </div>
            </b-col>

            <b-col cols="12" xl="6">
              <table class="mt-2 mt-xl-0 w-100">
                <tr>
                  <th class="pb-50">
                    <feather-icon icon="BriefcaseIcon" class="mr-75" />
                    <span class="font-weight-bold">Órgão</span>
                  </th>
                  <td class="pb-50">
                    {{ parametro.segurado.orgao.nome }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon icon="StarIcon" class="mr-75" />
                    <span class="font-weight-bold">Categoria</span>
                  </th>
                  <td class="pb-50 text-capitalize">
                    {{ parametro.segurado.situacaoPrevidenciaria }}
                  </td>
                </tr>
                <tr>
                  <th>
                    <feather-icon icon="PhoneIcon" class="mr-75" />
                    <span class="font-weight-bold">Telefone</span>
                  </th>
                  <td>
                    {{ parametro.segurado.tomador.telefone }}
                  </td>
                </tr>
                <tr>
                  <th>
                    <feather-icon icon="AtSignIcon" class="mr-75" />
                    <span class="font-weight-bold">E-mail</span>
                  </th>
                  <td>
                    {{ parametro.segurado.tomador.email }}
                  </td>
                </tr>
              </table>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col md="3">
              <b-form-group class="label-branco" :label="'Valor da ' + parametro.origem" label-for="valor">
                <b-form-input
                  id="valor"
                  placeholder="Valor da Proposta"
                  v-model="dadosProposta.valorDaProposta"
                  :readonly="true"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group class="label-branco" label="Parcelas" label-for="parcelas">
                <b-form-input
                  id="parcelas"
                  placeholder="Parcelas"
                  v-model="dadosProposta.quantidadeDeParcelas"
                  :readonly="true"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group class="label-branco" label="Vencimento da 1º Parcela" label-for="primeiraParcela">
                <b-form-input
                  id="primeiraParcela"
                  class="form-control"
                  type="text"
                  placeholder="DD/MM/AAAA"
                  v-model="dadosProposta.vencimentoDaPrimeiraParcela"
                  :readonly="true"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group class="label-branco" label="Valor da 1º Parcela" label-for="valorPrimeiraParcela">
                <b-form-input
                  id="valorPrimeiraParcela"
                  placeholder="Valor 1º Parcela"
                  v-model="dadosProposta.valorDaPrimeiraParcela"
                  :readonly="true"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group class="label-branco" label="Taxa %" label-for="taxa">
                <b-form-input id="taxa" placeholder="Taxa %" v-model="dadosProposta.taxa" :readonly="true" />
              </b-form-group>
            </b-col>
            <b-col md="3" v-if="montante.disponivel">
              <b-form-group class="label-branco" label="Montante disponível" label-for="montanteDisponivel">
                <b-form-input
                  id="montanteDisponivel"
                  class="form-control"
                  type="text"
                  v-model="montante.disponivel"
                  :readonly="true"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group class="label-branco" label="Valor total do empréstimo" label-for="totalEmprestimo">
                <b-form-input
                  id="valorTotalEmprestimo"
                  placeholder="Valor total do empréstimo"
                  v-model="parametro.valorComIOF"
                  :readonly="true"
                />
              </b-form-group>
            </b-col>
            <b-col md="3" v-if="utilizaIOF">
              <b-form-group class="label-branco" label="Valor do IOF" label-for="valorIOF">
                <b-form-input id="valorIOF" class="form-control" type="text" v-model="parametro.valorIOF" :readonly="true" />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group class="label-branco" label="Valor total do empréstimo com juros %" label-for="valorTotalComJuros">
                <b-form-input
                  id="valorTotalComJuros"
                  class="form-control"
                  v-model="parametro.valorTotalComJuros"
                  :readonly="true"
                />
              </b-form-group>
            </b-col>
            <b-col md="3" v-if="parametro.compraDeDivida === true">
              <b-form-group class="label-branco" label="Valor da Dívida" label-for="valorDaDivida">
                <b-form-input id="valorDaDivida" v-model="parametro.valorDaDivida" :disabled="true" />
              </b-form-group>
            </b-col>
            <!-- Saldo Remanescente -->
            <b-col md="3" v-if="parametro.compraDeDivida === true">
              <b-form-group class="label-branco" label="Saldo Remanescente" label-for="saldo Remanescente">
                <b-form-input id="saldoRemanescente" v-model="parametro.valorRemanescente" :disabled="true" />
              </b-form-group>
            </b-col>
            <!-- Banco-->
            <b-col md="3" v-if="parametro.compraDeDivida === true">
              <b-form-group class="label-branco" label="Banco" label-for="bancoDivida">
                <b-form-input id="bancoDivida" v-model="parametro.nomeBanco" :disabled="true" />
              </b-form-group>
            </b-col>
            <!-- Quantidade de parcelas quitadas -->
            <b-col md="3" v-if="parametro.compraDeDivida === true">
              <b-form-group class="label-branco" label="Quantidade de parcelas quitadas" label-for="parcelasQuitadas">
                <b-form-input id="parcelasQuitadas" v-model="parametro.parcelasQuitadas" :disabled="true" />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-row>
                <b-col lg="6" style="margin-top: 23px">
                  <b-button
                    class="btn w-100 btn-success"
                    @click="aprovarProposta(parametro.id)"
                    v-if="parametro.statusProposta == 'Pendente'"
                    v-show="parametro.acesso.PropostaAprovarProposta"
                  >
                    <feather-icon icon="CheckSquareIcon" size="16" />
                    <span class="align-middle ml-50">Aprovar</span>
                  </b-button>
                </b-col>
                <b-col lg="6" style="margin-top: 23px">
                  <b-button
                    class="btn w-100 btn-danger"
                    @click="negarProposta(parametro.id)"
                    v-if="parametro.statusProposta == 'Pendente'"
                    v-show="parametro.acesso.PropostaAprovarProposta"
                  >
                    <feather-icon icon="XSquareIcon" size="16" />
                    <span class="align-middle ml-50">Negar</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card-text>
      </b-card>
      <b-row>
        <b-col md="12">
          <b-table
            striped
            responsive
            class="position-relative"
            :per-page="perPageVisualizarParcelas"
            :current-page="currentPageVisualizarParcelas"
            :items="itemsVisualizarParcelas"
            :fields="fieldsVisualizarParcelas"
            :sort-by.sync="sortByVisualizarParcelas"
            :sort-desc.sync="sortDescVisualizarParcelas"
            :sort-direction="sortDirectionVisualizarParcelas"
          >
            <template #cell(valor)="row">
              {{ formatarValor(row.item.valor) }}
            </template>
            <template #cell(vencimento)="row">
              {{ formatarData(row.item.vencimento) }}
            </template>
          </b-table>

          <b-col md="12" v-if="itemsVisualizarParcelas.length == 0">
            <naoEncontradoModal />
          </b-col>

          <b-card-body class="d-flex justify-content-center flex-wrap pt-0" v-if="itemsVisualizarParcelas.length != 0">
            <b-pagination
              first-number
              last-number
              v-model="currentPageVisualizarParcelas"
              :total-rows="totalRowsVisualizarParcelas"
              :per-page="perPageVisualizarParcelas"
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-card-body>
        </b-col>
      </b-row>
    </b-form>

    <b-modal id="modal-auth-senha" ref="modal-auth-senha" centered size="sm" title="Autenticação" hide-footer>
      <AutenticaSenha @autenticarEvent="autenticarMethod" />
    </b-modal>
  </div>
</template>

<script>
  import useJwt from '@/auth/jwt/useJwt'
  import {
    detectarNavegador,
    formatarCodigoSequencial,
    formatarData,
    formatarParaNumber,
    formatarValor,
    formatarValorReais,
    isNumber,
  } from '@/libs/utils'
  import naoEncontradoModal from '@/views/error/nao-encontrado-modal.vue'
  import AutenticaSenha from '@/views/pages/auth/autenticaSenha.vue'
  import { avatarText } from '@core/utils/filter'
  import * as Math from 'mathjs'
  import { ValidationObserver, ValidationProvider } from 'vee-validate'
  import Ripple from 'vue-ripple-directive'
  import contratoGeraContrato from '../pages/financeiro/contrato/contratoGeraContrato.vue'

  export default {
    components: {
      formatarParaNumber,
      ValidationProvider,
      contratoGeraContrato,
      ValidationObserver,
      naoEncontradoModal,
      AutenticaSenha,
      formatarData,
      formatarCodigoSequencial,
      formatarValor,
      formatarValorReais,
      Math,
    },
    directives: {
      Ripple,
    },
    props: {
      parametro: {},
    },
    setup() {
      return {
        avatarText,
      }
    },
    data() {
      return {
        montante: {
          disponivel: undefined,
        },
        isNumber,
        formatarValor,
        formatarData,
        formatarCodigoSequencial,
        formatarValor,
        geraContrato: {},
        number: {},
        valorTotalEmprestimo: 0,
        recursos: {},
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        sortByVisualizarParcelas: '',
        sortDescVisualizarParcelas: false,
        sortDirectionVisualizarParcelas: 'asc',
        perPageVisualizarParcelas: 6,
        currentPageVisualizarParcelas: 1,
        fieldsVisualizarParcelas: [
          { key: 'parcela', sortable: true },
          { key: 'valor', sortable: true },
          { key: 'vencimento', sortable: true },
        ],

        totalRowsVisualizarParcelas: 0,
        itemsVisualizarParcelas: [],
        taxaShow: {},
        utilizaIOF: false,
        instituto: {},
        vendedor: '',
        valorMontanteDisponivel: 0,
        dadosProposta: {
          valorDaProposta: null,
          quantidadeDeParcelas: null,
          vencimentoDaPrimeiraParcela: null,
          valorDaPrimeiraParcela: null,
          taxa: null,
          valorComIOF: null,
        },
      }
    },
    async mounted() {
      this.instituto = await this.carregarInstituto()
      if (this.parametro.usuarioId) {
        this.carregarVendedor()
      }
      this.parametro.valorTotalComJuros = formatarValor(this.parametro.valorTotalComJuros)
      this.parametro.valorComIOF = this.parametro.valorComIOF ? formatarValor(this.parametro.valorComIOF) : this.parametro.valor
      this.parametro.valorIOF = this.parametro.valorIOF ? formatarValor(this.parametro.valorIOF) : formatarValor(0)

      this.carregarParcelas()
      try {
        this.montante.disponivel = await this.fetchMontanteDisponivel()
      } catch (e) {
        this.montante.disponivel = undefined
      }
      this.taxaShow = parseFloat(this.parametro.taxa.replace(/,/g, '')) / 100
      this.utilizaIOF = this.instituto.utilizaIOF

      // NOTE: Caso utilize IOF
      this.dadosProposta.valorDaProposta = formatarValor(this.parametro.valorBaseCalculo)
      this.dadosProposta.valorDaPrimeiraParcela =
        this.parametro.tipoCalculo == 'posFixado'
          ? this.parametro.valorPrimeiraParcela + ' + ' + this.userData.Instituto.indiceSelecionado
          : this.parametro.valorPrimeiraParcela
      this.dadosProposta.quantidadeDeParcelas = this.parametro.parcelas
      this.dadosProposta.vencimentoDaPrimeiraParcela = this.parametro.primeiraParcela
      this.dadosProposta.valorDaPrimeiraParcela = this.parametro.valorPrimeiraParcela
      this.dadosProposta.taxa = this.parametro.taxa
      this.dadosProposta.valorComIOF = this.parametro.valorComIOF ? this.parametro.valorComIOF : formatarValor(0)

      let valorDoContrato = Math.bignumber(formatarParaNumber(this.parametro.valor))
      let valorDoContratoComIOF = Math.bignumber(formatarParaNumber(this.parametro.valorComIOF))

      const valorTotalEmprestimo = Number(Math.subtract(valorDoContrato, valorDoContratoComIOF))
      this.valorTotalEmprestimo = formatarValor(Math.add(valorTotalEmprestimo, formatarParaNumber(this.parametro.valorComIOF)))
      this.parametro.valorDaDivida = formatarValor(this.parametro.valorDaDivida)
      this.parametro.valorRemanescente = formatarValor(this.parametro.valorRemanescente)
    },
    methods: {
      async carregarVendedor() {
        await useJwt
          .get(`cadastro/user/${this.parametro.usuarioId}`)
          .then((response) => {
            this.vendedor = response.data.name
          })
          .catch((error) => {
            console.error(error)
          })
      },
      async carregarInstituto(id = this.userData.institutoSelecionado) {
        try {
          const instituto = (await useJwt.get(`cadastro/instituto/${id}`)).data
          return instituto
        } catch (e) {
          console.error('Erro ao carregar instituto:', e)
        }
      },
      calculoValorAlocado(item) {
        return Number(item.valorAlocado)
      },
      calculoValorDisponivel(item) {
        return (
          item.valorAlocado +
          (item.valorAmortizacao ? Number(item.valorAmortizacao) : 0) -
          Number(item.montanteUtilizadoCumulativo)
        )
      },
      calculaMontanteAcumulado(data, montantes) {
        // Sort the data based on 'competencia'
        const sortedData = data.sort((a, b) => a.competencia - b.competencia)

        // Calculate the cumulative sum of 'montanteUtilizado'
        let cumulativeSum = 0
        for (let item of sortedData) {
          cumulativeSum += parseFloat(Number(montantes[item.competencia] ?? 0))
          item.montanteUtilizadoCumulativo = cumulativeSum.toFixed(2)
        }

        return sortedData
      },
      async fetchMontanteDisponivel() {
        const response = await useJwt.pesquisar('cadastro/instituto/GetpesquisarRecursos', {
          institutoId: this.userData.Instituto.id,
        })
        let items = response.data

        this.valorMontanteDisponivel = Number(response.data[0].valorDisponivel)

        return formatarValor(this.valorMontanteDisponivel)
      },
      async autenticarMethod(data) {
        if (data) {
          const id = this.userData.id

          let navegadorUtilizado = detectarNavegador()
          const response = await useJwt.pesquisar('proposta/aprovarProposta', {
            idProposta: this.propostaSelecionada,
            usuario: id,
            navegadorUtilizado,
          })

          if (response.data['status'] == 'alerta') {
            let mensagem = `Encontramos inconsistências: <br><br>`
            mensagem += response.data['mensagem']

            this.$swal({
              title: 'Atenção!',
              html: mensagem,
              icon: 'warning',
              confirmButtonText: 'Fechar',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
              buttonsStyling: false,
            })
          } else {
            this.$refs['modal-auth-senha'].hide()
            this.$emit('atualizarProposta', this.propostaSelecionada, 'Aprovado')
            const { data } = await useJwt.post('financeiro/GetContratoPelaProposta', { id: this.propostaSelecionada })
            this.$emit('abreModalContrato', data)
          }
        }
      },
      negarProposta(item) {
        this.$swal({
          title: 'Atenção!',
          text: 'Deseja realmente NEGAR a proposta selecionado?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim!',
          cancelButtonText: 'Não!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-danger ml-1',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            const id = this.userData.id

            this.$swal({
              title: 'Observação',
              input: 'text',
              allowEscapeKey: false,
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-secondary ml-1',
              },
              buttonsStyling: false,
              inputAttributes: {
                autocapitalize: 'off',
                required: true,
              },
              inputValidator: (value) => {
                return !value && 'Por favor informe o motivo!'
              },
              showCancelButton: true,
              confirmButtonText: 'Confirmar',
              cancelButtonText: 'Fechar',
              showLoaderOnConfirm: true,
              preConfirm(motivo) {
                return useJwt
                  .pesquisar('proposta/negarProposta', {
                    idProposta: item,
                    usuario: id,
                    motivoNegado: motivo,
                  })
                  .then((response) => {})
                  .catch((error) => {
                    console.error(error)
                  })
              },
              allowOutsideClick: () => !this.$swal.isLoading(),
            }).then((result) => {
              if (result.value) {
                this.$swal({
                  title: 'Atenção!',
                  text: 'Proposta negada com sucesso',
                  icon: 'success',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                }).then((result) => {
                  this.$emit('reloadComponent')
                })
              }
            })
          }
        })
      },
      async aprovarProposta(item) {
        // NOTE: Mensagem validação de dados bancários
        // TODO: Verificar outras mensagens
        let tomador = this.parametro.segurado.tomador
        let segurado = this.parametro.segurado
        let mensagem = `Tomador não possui o(s) dado(s) bancário(s) cadastrado(s): <br><br>`
        let possuiErro = false
        let aux = null

        // NOTE: Dados bancários
        let dadoBancarioAgencia = tomador.dadoBancarioAgencia
        if (!dadoBancarioAgencia) {
          mensagem += `O número de agência não foi informado.<br>`
          possuiErro = true
        }
        let dadoBancarioAgenciaDV = tomador.dadoBancarioAgenciaDV
        if (!dadoBancarioAgenciaDV) {
          mensagem += `O dígito da agência não foi informado.<br>`
          possuiErro = true
        }
        let dadoBancarioConta = tomador.dadoBancarioConta
        if (!dadoBancarioConta) {
          mensagem += `O número da conta não foi informado.<br>`
          possuiErro = true
        }
        let dadoBancarioContaDV = tomador.dadoBancarioContaDV
        if (!dadoBancarioContaDV) {
          mensagem += `O dígito da conta não foi informado.<br>`
          possuiErro = true
        }
        let dadoBancarioContaOP = tomador.dadoBancarioContaOP
        if (!dadoBancarioContaOP) {
          mensagem += `O número da operação não foi informado.<br>`
          possuiErro = true
        }
        let dadoBancarioFormaDePagamento = tomador.dadoBancarioFormaDePagamento
        if (!dadoBancarioFormaDePagamento) {
          mensagem += `A forma de pagamento não foi informada.<br>`
          possuiErro = true
        }

        const montanteDisp = this.valorMontanteDisponivel

        if (montanteDisp <= 0) {
          if (!possuiErro) {
            mensagem = `A unidade gestora possui a seguinte pendência: <br><br> O montante disponivel não pode ser menor ou igual a 0.<br>`
          } else {
            mensagem += `<br><br>A unidade gestora possui a seguinte pendência: <br><br> O montante disponivel não pode ser menor ou igual a 0.<br>`
          }
          possuiErro = true
        }

        if (possuiErro) {
          this.$swal({
            title: 'Atenção!',
            html: mensagem,
            icon: 'warning',
            confirmButtonText: 'Fechar',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
            buttonsStyling: false,
          })
        } else {
          this.$swal({
            title: 'Atenção!',
            text: 'Deseja realmente APROVAR a proposta selecionada?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim!',
            cancelButtonText: 'Não!',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-danger ml-1',
            },
            buttonsStyling: false,
          }).then((result) => {
            if (result.value) {
              this.propostaSelecionada = item
              this.$refs['modal-auth-senha'].show()
            }
          })
        }
      },
      carregarParcelas() {
        useJwt
          .get(`proposta/carregarParcelas/${this.parametro.id}`)
          .then((response) => {
            let dados = response.data
            this.itemsVisualizarParcelas = dados
            this.totalRowsVisualizarParcelas = dados.length
            // Retornado pois o valor ainda estava vindo como NaN
            this.parametro.valorPrimeiraParcela = formatarValor(this.parametro.valorPrimeiraParcela)
          })
          .catch((error) => {
            console.error(error)
          })
      },
    },
  }
</script>

<style>
  .label-branco label {
    color: white;
  }
</style>
