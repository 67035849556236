<template>
  <form-wizard
    shape="circle"
    color="#3949ab"
    :title="null"
    :subtitle="null"
    back-button-text="Voltar"
    next-button-text="Próximo"
    finish-button-text="Finalizar"
    class="mb-3"
    @on-complete="salvarSimulacao"
  >
    <template slot="footer" slot-scope="props">
      <div class="wizard-footer-left">
        <wizard-button
          v-if="props.activeTabIndex > 0"
          :style="props.fillButtonStyle"
          @click.native="props.prevTab(), limparCampos(props.activeTabIndex)"
        >
          Voltar
        </wizard-button>
      </div>
      <div class="wizard-footer-right w-50">
        <div class="d-flex flex-row flex-nowrap justify-content-end">
          <div v-if="props.activeTabIndex == 2" md="6">
            <b-button variant="primary" @click="validarBotaoCalculo()"> Calcular </b-button>
          </div>
          <div v-if="!props.isLastStep" class="ml-1">
            <wizard-button
              :disabled="!habilitarBotaoProximoCalcular(props.activeTabIndex)"
              :style="props.fillButtonStyle"
              @click.native="props.nextTab()"
            >
              Próximo
            </wizard-button>
          </div>
          <div v-else class="ml-1">
            <b-button variant="primary" :style="props.fillButtonStyle" @click="salvarSimulacao">
              {{ props.isLastStep ? 'Finalizar' : 'Próximo' }}
            </b-button>
          </div>
        </div>
      </div>
    </template>

    <!-- Segurado -->
    <tab-content v-if="!origemAplicativo" title="Segurado" :before-change="validationDadosSegurado">
      <validation-observer ref="tomRules" tag="form">
        <!-- Consulta Segurado -->
        <b-form-group v-show="campoSeguradoSelecionado" label="Buscar por:">
          <b-form-radio-group v-model="tipoBusca" name="buscaPor" class="mb-1">
            <b-form-radio size="sm" :value="1" variant="primary" @change="limparCampoSegurado">Nome</b-form-radio>
            <b-form-radio size="sm" :value="2" variant="primary" @change="limparCampoSegurado">CPF</b-form-radio>
          </b-form-radio-group>
          <validation-provider #default="{ errors }" name="Segurado" rules="required" v-if="tipoBusca == 1">
            <b-skeleton v-if="isBusySegurado" type="input" />
            <vue-autosuggest
              v-else
              id="segurado"
              v-model="proposta.segurado"
              :suggestions="suggestions"
              :input-props="{
                id: 'autosuggest__input',
                class: 'form-control',
                placeholder: 'Digite aqui o nome do segurado',
              }"
              @selected="getSuggestionValue"
              @input="fetchResults"
            >
              <template slot-scope="{ suggestion }">
                <div
                  class="tw-flex tw-flex-row tw-justify-between tw-items-center"
                  :disabled="suggestion.item.diasDeCarenciaBloqueado"
                >
                  <div class="d-flex align-items-center">
                    <b-avatar :src="suggestion.item.tomador.foto ? suggestion.item.tomador.foto.foto : null" />
                    <div class="detail ml-50">
                      <b-card-text class="mb-0">
                        {{ suggestion.item.tomador.nomeCompleto }}
                      </b-card-text>
                      <small class="text-muted">
                        CPF: {{ suggestion.item.tomador.cpf }} - Categoria: {{ suggestion.item.situacaoPrevidenciaria }} -
                        Matrícula:
                        {{ suggestion.item.matricula }}
                      </small>
                    </div>
                  </div>
                  <div
                    v-if="suggestion.item.diasDeCarenciaBloqueado"
                    class="tw-text-sm tw-bg-gray-700/60 tw-text-white text-center tw-rounded-3xl tw-p-2"
                  >
                    Período de carência
                  </div>
                </div>
              </template>
            </vue-autosuggest>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          <validation-provider #default="{ errors }" name="Segurado" rules="required" v-else>
            <b-skeleton v-if="isBusySegurado" type="input" />
            <vue-autosuggest
              v-else
              id="cpf-input"
              v-model="proposta.segurado"
              type="number"
              maxlength="14"
              :suggestions="suggestions"
              :input-props="{
                id: 'autosuggest__input',
                class: 'form-control',
                placeholder: 'Digite aqui o CPF do segurado',
                maxlength: 14,
              }"
              @selected="getSuggestionValue"
              @input="fetchResults"
            >
              <template slot-scope="{ suggestion }">
                <div
                  class="tw-flex tw-flex-row tw-justify-between tw-items-center"
                  :disabled="suggestion.item.diasDeCarenciaBloqueado"
                >
                  <div class="d-flex align-items-center">
                    <b-avatar :src="suggestion.item.tomador.foto ? suggestion.item.tomador.foto.foto : null" />
                    <div class="detail ml-50">
                      <b-card-text class="mb-0">
                        {{ suggestion.item.tomador.nomeCompleto }}
                      </b-card-text>
                      <small class="text-muted">
                        CPF: {{ suggestion.item.tomador.cpf }} - Categoria: {{ suggestion.item.situacaoPrevidenciaria }} -
                        Matrícula:
                        {{ suggestion.item.matricula }}
                      </small>
                    </div>
                  </div>
                  <div
                    v-if="suggestion.item.diasDeCarenciaBloqueado"
                    class="tw-text-sm tw-bg-gray-700/60 tw-text-white text-center tw-rounded-3xl tw-p-2"
                  >
                    Período de carência
                  </div>
                </div>
              </template>
            </vue-autosuggest>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <!-- Campos do tomador -->
        <b-row v-show="this.exibeDadosSegurado">
          <b-col md="3">
            <b-form-group label="CPF" label-for="cpf">
              <b-form-input id="cpf" v-model="segurado.tomador.cpf" readonly type="text" placeholder="CPF" />
            </b-form-group>
          </b-col>
          <b-col md="9">
            <b-form-group label="Nome Completo" label-for="nomeCompleto">
              <b-form-input
                id="nomeCompleto"
                v-model="segurado.tomador.nomeCompleto"
                readonly
                type="text"
                placeholder="Nome Completo"
                style="text-transform: uppercase"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-show="this.exibeDadosSegurado">
          <b-col md="3">
            <b-form-group label="Categoria" label-for="categoria">
              <b-form-input id="categoria" readonly type="text" placeholder="Categoria" :value="segurado.categoria" />
            </b-form-group>
          </b-col>
          <b-col md="9">
            <b-form-group label="Nome Social" label-for="nomeSocial">
              <b-form-input
                id="nomeSocial"
                v-model="segurado.tomador.nomeSocial"
                readonly
                type="text"
                placeholder="Nome Social"
                style="text-transform: uppercase"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-show="this.exibeDadosSegurado">
          <b-col md="3">
            <b-form-group label="Data de Nascimento" label-for="dataNascimento">
              <b-form-input
                id="dataNascimento"
                v-model="segurado.tomador.dataNascimento"
                readonly
                type="text"
                placeholder="Data de Nascimento"
                style="text-transform: uppercase"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Remuneração" label-for="remuneracaoProventos">
              <b-form-input
                id="remuneracaoProventos"
                v-model="segurado.remuneracaoProventos"
                readonly
                type="text"
                placeholder="Remuneração"
                style="text-transform: uppercase"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Margem Utilizada" label-for="margemUtilizada">
              <b-form-input
                id="margemUtilizada"
                v-model="segurado.margemUtilizada"
                readonly
                type="text"
                placeholder="Margem Utilizada"
                style="text-transform: uppercase"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Margem Disponível" label-for="margemDisponivel">
              <b-form-input
                id="margemDisponivel"
                v-model="segurado.margemDisponivel"
                readonly
                type="text"
                placeholder="Margem Disponível"
                style="text-transform: uppercase"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
    </tab-content>

    <!-- Tipo de Cálculo  -->
    <tab-content title="Tipo de Cálculo" :before-change="validationTipoCalculo">
      <b-card-title v-if="origemAplicativo" title-tag="h3" class="font-weight-bold mb-2 mt-2"> Tipo de Cálculo </b-card-title>

      <validation-observer ref="tipRules" tag="form" rules="required">
        <b-row>
          <b-col :md="origemAplicativo ? 12 : 6">
            <b-form-group v-slot="{ ariaDescribedby }" label="Tabela de Cálculo">
              <validation-provider #default="{ errors }" name="Tabela de Cálculo" rules="required">
                <b-form-select
                  v-model="proposta.tipoCalculo"
                  :options="statusTipoCalculo"
                  :aria-describedby="ariaDescribedby"
                  placeholder="Selecione:"
                  @change="buscarTipoDeCalculo"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Forma de Cálculo. Por valor da parcela ou pelo valor do empréstimo -->
          <b-col :md="origemAplicativo ? 12 : 6">
            <b-form-group v-slot="{ ariaDescribedby }" label="Forma de Cálculo">
              <validation-provider #default="{ errors }" name="Forma de Cálculo" rules="required">
                <b-form-radio-group
                  id="radiosFormasDeCalculo"
                  v-model="proposta.formaCalculo"
                  :options="optionsFormaCalculo"
                  :aria-describedby="ariaDescribedby"
                  button-variant="outline-primary"
                  name="radio-btn-outline"
                  buttons
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col :md="origemAplicativo ? 12 : 6">
            <b-form-group v-slot="{ ariaDescribedby }" label="Compra de Dívida?">
              <validation-provider #default="{ errors }" name="Compra de Dívida?" rules="required">
                <b-form-radio-group
                  id="compraDeDivida"
                  v-model="proposta.compraDeDivida"
                  :options="[
                    { text: 'SIM', value: true },
                    { text: 'NÃO', value: false },
                  ]"
                  :aria-describedby="ariaDescribedby"
                  button-variant="outline-primary"
                  name="radio-btn-outline"
                  buttons
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
    </tab-content>

    <!-- Dados da consignação:  -->
    <tab-content title="Dados da consignação" :before-change="validationDadosConsignacao">
      <b-card-title v-if="origemAplicativo" title-tag="h3" class="font-weight-bold mb-2 mt-2">
        Dados da consignação
      </b-card-title>

      <validation-observer ref="dadosRules" tag="form">
        <b-row>
          <b-col md="4">
            <b-form-group label="Valor da Proposta" label-for="valor">
              <b-skeleton v-if="calculandoParcela" type="input" />
              <validation-provider
                v-else
                #default="{ errors }"
                name="Valor da Proposta"
                ref="valorProposta"
                :rules="{
                  required: true,
                  valor: Number(proposta.valorMaximoGeral).toFixed(2),
                  valor_minimo: formatarParaNumber(parametroValorMinimoEmprestimo),
                  zero: [],
                }"
              >
                <b-input-group>
                  <b-input-group-prepend>
                    <span class="input-group-text">R$</span>
                  </b-input-group-prepend>
                  <b-form-input
                    id="valor"
                    v-model="proposta.valor"
                    v-number="number"
                    placeholder="Valor da Proposta"
                    :readonly="proposta.formaCalculo == 'valorDaParcela'"
                  />
                </b-input-group>
                <small
                  v-if="proposta.valorMaximoGeral != null || proposta.valorMaximoGeral != undefined"
                  class="text-primary d-block"
                  >Valor Máx ({{ formatarValor(proposta.valorMaximoGeral) }})</small
                >
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Parcelas" label-for="parcelas">
              <validation-provider
                #default="{ errors }"
                name="Parcelas"
                ref="qtdParcelas"
                :rules="{
                  required: true,
                  parcela: proposta.parcelaMaxima,
                  zero: [],
                }"
              >
                <b-form-input
                  id="parcelas"
                  v-model="proposta.parcelas"
                  v-bloqueioCaractereMask="{ tamanho: 3, regex: /[^0-9]/ }"
                  placeholder="Parcelas"
                  maxlength="3"
                  @keypress="isNumber($event)"
                />
                <small v-if="proposta.parcelaMaxima" class="text-primary d-block"
                  >Parcela(s) Máx ({{ proposta.parcelaMaxima }}x)</small
                >
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              :label="
                proposta.tipoCalculo == 'SAC'
                  ? 'Valor da 1º Parcela'
                  : proposta.tipoCalculo == 'posFixado'
                  ? 'Valor Previsto'
                  : 'Valor da Parcela'
              "
              label-for="valorPrimeiraParcela"
            >
              <b-skeleton v-if="calculandoEmprestimo" type="input" />
              <validation-provider
                v-else
                #default="{ errors }"
                name="Valor da Parcela"
                ref="valorParcela"
                :rules="{
                  required: true,
                  valor: proposta.valorMaximoPrimeiraParcela,
                  valor_minimo: formatarParaNumber(parametroValorMinimoParcela),
                  zero: [],
                }"
              >
                <b-input-group>
                  <b-input-group-prepend>
                    <span class="input-group-text">R$</span>
                  </b-input-group-prepend>
                  <b-form-input
                    id="valorPrimeiraParcela"
                    v-model="proposta.valorPrimeiraParcela"
                    v-number="number"
                    :placeholder="
                      proposta.tipoCalculo == 'SAC'
                        ? 'Valor da 1º Parcela'
                        : proposta.tipoCalculo == 'posFixado'
                        ? 'Valor Previsto'
                        : 'Valor da Parcela'
                    "
                    :readonly="proposta.formaCalculo == 'valorDoEmprestimo'"
                  />
                </b-input-group>
                <small
                  v-if="proposta.valorMaximoPrimeiraParcela != null || proposta.valorMaximoPrimeiraParcela != undefined"
                  class="text-primary d-block"
                  >Valor Máx ({{ formatarValor(proposta.valorMaximoPrimeiraParcela) }})</small
                >
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col v-if="proposta.compraDeDivida" md="4">
            <b-form-group label="Valor da Dívida" label-for="valor">
              <validation-provider
                #default="{ errors }"
                name="Valor da Dívida"
                :rules="{
                  required: true,
                  zero: [],
                  valor: proposta.valor,
                }"
              >
                <b-input-group>
                  <b-input-group-prepend>
                    <span class="input-group-text">R$</span>
                  </b-input-group-prepend>
                  <b-form-input
                    id="valorDaDivida"
                    v-model="proposta.valorDaDivida"
                    v-number="number"
                    placeholder="Valor da Dívida"
                  />
                </b-input-group>
                <small v-if="proposta.valor != null || proposta.valorDaDivida != undefined" class="text-primary d-block"
                  >Valor da Dívida ({{
                    formatarValor(
                      formatarParseFloat(proposta.valor ? proposta.valor : '0') -
                        formatarParseFloat(proposta.valorDaDivida ? proposta.valorDaDivida : '0'),
                    )
                  }})</small
                >
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="1º Parcela" label-for="primeiraParcela">
              <b-form-input
                id="primeiraParcela"
                v-model="proposta.primeiraParcela"
                class="form-control"
                type="text"
                placeholder="DD/MM/AAAA"
                :options="options.cleaveDate"
                :raw="false"
                :readonly="true"
              />
            </b-form-group>
          </b-col>
          <b-col v-if="userData.Instituto.utilizaIOF" md="4">
            <b-form-group label="Valor do IOF" label-for="valorIOF">
              <b-skeleton v-if="calculandoParcela || calculandoEmprestimo" type="input" />
              <b-input-group v-else>
                <b-input-group-prepend>
                  <span class="input-group-text">R$</span>
                </b-input-group-prepend>
                <b-form-input
                  id="valorIOF"
                  v-model="proposta.valorIOF"
                  v-number="number"
                  placeholder="Valor do IOF"
                  :readonly="true"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col v-if="proposta.compraDeDivida" md="8">
            <b-form-group label="Banco" label-for="dadoBancarioBanco">
              <validation-provider #default="{ errors }" name="Banco" rules="required">
                <v-select-pt
                  v-model="proposta.bancoId"
                  name="dadoBancarioBanco"
                  :options="banco"
                  :reduce="(option) => option.id"
                  label="nome"
                  @input="encontrarNomeBanco(proposta.bancoId)"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col v-if="proposta.compraDeDivida" md="4">
            <b-form-group label="Valor Remanescente" label-for="valorRemanescente">
              <b-skeleton v-if="calculandoParcela || calculandoEmprestimo" type="input" />
              <b-input-group v-else>
                <b-input-group-prepend>
                  <span class="input-group-text">R$</span>
                </b-input-group-prepend>
                <b-form-input
                  id="valorRemanescente"
                  v-model="proposta.valorRemanescente"
                  v-number="number"
                  placeholder="Valor Remanscente"
                  :readonly="true"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Valor Emprestado" label-for="valorTotal">
              <b-skeleton v-if="calculandoParcela || calculandoEmprestimo" type="input" />
              <b-input-group v-else>
                <b-input-group-prepend>
                  <span class="input-group-text">R$</span>
                </b-input-group-prepend>
                <b-form-input
                  id="valorTotal"
                  v-number="number"
                  placeholder="Valor Emprestado"
                  v-model="proposta.valorComIOF"
                  :readonly="true"
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group v-if="proposta.compraDeDivida" label="Número parcelas quitadas" label-for="parcelasQuitadas">
              <b-input-group>
                <b-form-input
                  id="parcelasQuitadas"
                  placeholder="Número parcelas quitadas"
                  v-model="proposta.parcelasQuitadas"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
    </tab-content>

    <!-- Tabela de Parcelas  -->
    <tab-content title="Tabela de Parcelas" :before-change="validationTabelaParcelas">
      <b-card-title v-if="origemAplicativo" title-tag="h3" class="font-weight-bold mb-2 mt-2">
        Tabela de Parcelas
      </b-card-title>

      <validation-observer ref="bankRules" tag="form">
        <b-form>
          <b-row>
            <b-table
              striped
              responsive
              foot-clone
              class="position-relative"
              :per-page="perPageParcelas"
              :current-page="currentPageParcelas"
              :items="itemsParcelas"
              :fields="fieldsParcelas"
              :sort-by.sync="sortByParcelas"
              :sort-desc.sync="sortDescParcelas"
              :sort-direction="sortDirectionParcelas"
              :busy="isBusy"
            >
              <template #cell(valor)="row">
                {{
                  proposta.tipoCalculo == 'posFixado'
                    ? formatarValor(row.item.valor) + ' + ' + userData.Instituto.tipoIndiceSelecionado
                    : formatarValor(row.item.valor)
                }}
              </template>

              <template #cell(vencimento)="row">
                {{ formatarData(row.item.vencimento) }}
              </template>

              <template #foot(parcela)>
                <span>Total: </span>
              </template>

              <template #foot(valor)>
                <span>{{ valorTotalParcelas ? valorTotalParcelas : 0.0 }}</span>
              </template>

              <template #foot(vencimento)>
                {{ '' }}
              </template>
            </b-table>

            <b-card-body class="d-flex justify-content-center flex-wrap pt-0">
              <b-pagination
                first-number
                last-number
                v-model="currentPageParcelas"
                :total-rows="totalRowsParcelas"
                :per-page="perPageParcelas"
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-card-body>
          </b-row>
        </b-form>
      </validation-observer>
    </tab-content>
  </form-wizard>
</template>
<script>
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required, valor } from '@validations'
  import { isCPFValid } from '@/utils/Functions/Validacoes/Validacoes'
  import useJwt from '@/auth/jwt/useJwt'
  import Ripple from 'vue-ripple-directive'
  import { VueAutosuggest } from 'vue-autosuggest'
  import {
    isNumber,
    formatarValor,
    formatarData,
    formatarParseFloat,
    formatarParaNumber,
    detectarNavegador,
    formatarValorReais,
    calcularIdade,
    arrendondarCalculo,
  } from '@/libs/utils'
  import Cleave from 'vue-cleave-component'
  import { FormWizard, TabContent, WizardButton } from 'vue-form-wizard'
  import 'vue-form-wizard/dist/vue-form-wizard.min.css'
  import Banco from '@core/components/banco/Banco.vue'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import { parse } from 'date-fns'
  import * as math from 'mathjs'

  export default {
    components: {
      Banco,
      ValidationProvider,
      WizardButton,
      ValidationObserver,
      VueAutosuggest,
      Cleave,
      FormWizard,
      TabContent,
      formatarData,
      calcularIdade,
      formatarParaNumber,
      arrendondarCalculo,
    },
    directives: {
      Ripple,
    },
    props: {
      parametro: {
        origem: {
          type: String,
        },
      },
    },
    data() {
      return {
        origemAplicativo: false,
        isBusySegurado: false,
        formatarParaNumber,
        calculou: false,
        calculandoParcela: false,
        calculandoEmprestimo: false,
        parametroValorMinimoEmprestimo: 0,
        parametroValorMinimoParcela: 0,
        banco: [],
        formatarParseFloat,
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        cadastroFinalizado: false,
        isBusy: false,
        loading: false,
        isNumber,
        formatarValor,
        formatarData,
        tipoBusca: 1,
        itemChange: false,
        number: {},
        proposta: {
          bancoId: undefined,
          status: 0,
          valorComIOF: 0,
          parcelaComIOF: 0,
          valorIOF: 0,
          taxaIOF: 0,
          compraDeDivida: false,
          valorDaDivida: 0,
          valorTotal: undefined,
          tipoCalculo: null,
          valorRemanescente: 0,
          parcelasQuitadas: null,
        },
        segurado: { tomador: {} },
        suggestions: [],
        timeout: null,
        debounceMilliseconds: 300,
        required,
        valor: 0,
        sortByParcelas: '',
        sortDescParcelas: false,
        sortDirectionParcelas: 'asc',
        perPageParcelas: 12,
        currentPageParcelas: 1,
        fieldsParcelas: ['parcela', 'valor', 'vencimento'],
        totalRowsParcelas: 0,
        itemsParcelas: [],
        valorTotalParcelas: 0,
        exibeDadosSegurado: false,
        vinculoOpcoesCalculo: false,
        cleaveCurrency: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          delimiter: '.',
          numeralDecimalMark: ',',
          numeralDecimalScale: 2,
        },
        options: {
          cleaveCPF: {
            delimiters: ['.', '.', '-'],
            blocks: [3, 3, 3, 2],
          },
          cleaveDate: {
            date: true,
            delimiter: '/',
            datePattern: ['d', 'm', 'Y'],
          },
        },
        statusTipoCalculo: [],
        optionsFormaCalculo: [
          { value: 'valorDoEmprestimo', text: 'Valor do Empréstimo' },
          { value: 'valorDaParcela', text: 'Valor da Parcela' },
        ],
        dados: [],
        pesquisar: {
          tipoOpcoes: 1,
          descricao: '',
          perPage: 10,
          currentPage: 1,
          statusOpcoes: '',
        },
        tipoOpcoes: [
          { value: 1, text: 'Nome' },
          { value: 2, text: 'CPF' },
          { value: 3, text: 'Matrícula' },
        ],
        statusOpcoes: [
          { value: '', text: 'Todos' },
          { value: 'GerouProposta', text: 'Gerou Proposta' },
          { value: 'Pendente', text: 'Pendente' },
          { value: 'Expirado', text: 'Expirado' },
        ],
        options: {
          cleaveCPF: {
            delimiters: ['.', '.', '-'],
            blocks: [3, 3, 3, 2],
          },
          cleaveDate: {
            date: true,
            delimiter: '/',
            datePattern: ['d', 'm', 'Y'],
          },
        },
        campoSeguradoSelecionado: true,

        // Permissões
        utilizaIOF: false,
        utilizaFloatFinanceiro: false,

        // Índices de Referência
        competenciaDaTaxa: null,
        taxaDaCompetencia: null,
      }
    },
    watch: {
      'proposta.tipoCalculo': function (valor) {
        if (valor == 'posFixado') {
          this.optionsFormaCalculo = [{ text: 'Valor do Empréstimo', value: 'valorDoEmprestimo' }]
          this.proposta.formaCalculo = 'valorDoEmprestimo'
        } else {
          this.optionsFormaCalculo = [
            { text: 'Valor do Empréstimo', value: 'valorDoEmprestimo' },
            { text: 'Valor da Parcela', value: 'valorDaParcela' },
          ]
        }
      },
    },
    async created() {
      this.origemAplicativo = this.parametro.origem == 'Aplicativo'
      if (this.origemAplicativo) this.preparaComponenteParaAplicativo()
    },
    async mounted() {
      this.proposta = {
        ...this.proposta,
        institutoSelecionado: this.userData.institutoSelecionado,
      }
      await this.buscarTipoDeCalculo()
      this.parametroValorMinimoEmprestimo = this.userData.Instituto.valorMinimoEmprestimo
      this.parametroValorMinimoParcela = this.userData.Instituto.valorMinimoParcela
      this.exibeDadosSegurado = false
      this.proposta.formaCalculo = 'valorDoEmprestimo'
      this.utilizaIOF = this.userData.Instituto.utilizaIOF
      this.utilizaFloatFinanceiro = this.userData.Instituto.utilizaFloatFinanceiro
      await this.carregarBancos()
      this.origemAplicativo = this.parametro.origem == 'Aplicativo'
    },
    methods: {
      habilitarBotaoProximoCalcular(aba) {
        if (aba === 2) {
          return this.calculou === true
        }
        return true
      },
      findClosestValue(arr, target) {
        target = formatarParaNumber(target)
        if (!arr || arr.length === 0) {
          return null
        } // Handle empty array case

        let closest = Number(Number(arr[0].parcelaReal).toFixed(2))
        let index = 0
        for (let i = 0; i < arr.length; i++) {
          if (Math.abs(target - arr[i].parcelaReal) < Math.abs(target - closest)) {
            closest = Number(Number(arr[i].parcelaReal).toFixed(2))
            index = i
          }
        }

        return arr[index]
      },

      async carregarBancos() {
        await useJwt
          .get('utils/bancos')
          .then((response) => {
            this.banco = response.data
            this.banco.forEach((banco) => {
              if (banco.codigo) banco.nome = `${String(banco.codigo)} - ${String(banco.nome)}`
            })
          })
          .catch((error) => {
            console.error(error)
          })
      },
      validarCalculoParcelas() {
        if (this.proposta.parcelas == undefined || !(this.proposta.parcelas > 0)) {
          this.$message.error('Preencha o número de parcelas.')
          return false
        }
        if (this.proposta.valorPrimeiraParcela == undefined) {
          this.$message.error('Preencha o valor de parcela.')
          return false
        }
        return true
      },

      encontrarNomeBanco(id) {
        const bancoEncontrado = this.banco.find((banco) => banco.id === id)
        if (bancoEncontrado) {
          this.proposta.nomeBanco = bancoEncontrado.nome
        }
      },

      async calcularvalorRemanescente() {
        const valor = formatarParaNumber(this.proposta.valor)
        const valorDaDivida = formatarParaNumber(this.proposta.valorDaDivida)
        if (valor > valorDaDivida) {
          this.proposta.valorRemanescente = formatarValorReais(valor - valorDaDivida)
        } else {
          this.proposta.valorRemanescente = formatarValorReais('0.00')
        }
      },
      async calcularProposta(event) {
        this.calculou = true

        switch (this.proposta.formaCalculo) {
          case 'valorDoEmprestimo':
            this.calculandoEmprestimo = true
            await this.calcularPeloValorDoEmprestimo(event)
            await this.recalcular()

            break
          case 'valorDaParcela':
            this.calculandoParcela = true
            await this.calcularPeloValorDaParcela(event)
            break
        }

        this.proposta.valorEmprestado = formatarValorReais(
          formatarParaNumber(this.proposta.valor) + formatarParaNumber(this.proposta.valorIOF),
        )
        if (this.proposta.compraDeDivida) {
          await this.calcularvalorRemanescente()
        }
        this.calculandoParcela = false
        this.calculandoEmprestimo = false
      },
      async calcularPeloValorDoEmprestimo() {
        try {
          if (parcelas < 0 || valor < 0) {
            return this.msgError('Preencha o número de parcelas.')
          }
          const parametros = {
            idSegurado: this.segurado.id,
            institutoSelecionado: this.proposta.institutoSelecionado,
            valor,
            parcelas: this.proposta.parcelas,
            valorPrimeiraParcela,
            tipoCalculo: this.proposta.tipoCalculo,
            formaCalculo: this.proposta.formaCalculo,
          }
          await useJwt
            .post('utils/GetcalcularValoresSegurado', parametros)
            .then((response) => {
              this.proposta.valorPrimeiraParcela = response.data.valorPrimeiraParcela
              this.proposta.valorDoEmprestimo = this.formatarValorToText(response.data.valorDoEmprestimo)
              this.proposta.valorEfetivo = response.data.valor
              this.proposta.valorMaximoGeral = response.data.valorMaximoGeral
              this.proposta.taxa = Number(response.data.taxa)
              this.proposta.parcelaMaxima = response.data.parcelaMaxima
            })
            .catch((error) => {
              this.msgError('Erro ao calcular empréstimo')
              this.calculandoEmprestimo = false
              console.error(error)
            })
        } catch (error) {
          this.msgError('Erro ao calcular empréstimo')
          this.calculandoEmprestimo = false
          console.error(error)
        }
      },
      async calcularPeloValorDaParcela() {
        try {
          const valorDaParcela = this.proposta.valorPrimeiraParcela

          const parametros = {
            utilizaFloatFinanceiro: this.utilizaFloatFinanceiro,
            utilizaIOF: this.utilizaIOF,
            parcelas: Number(this.proposta.parcelas),
            primeiraParcela: parse(this.proposta.primeiraParcela, 'dd/MM/yyyy', new Date()).toISOString(),
            valorParcela: formatarParaNumber(this.proposta.valorPrimeiraParcela),
            taxaEmprestimoMensal: Number(this.userData.Instituto.taxaEmprestimoMensal),
            mesVencimento: Number(this.userData.Instituto.mesVencimento),
            diaDeRepasse: Number(this.proposta.diaDeRepasse),
            diaFechamentoFolha: Number(this.proposta.diaFechamentoFolha),
            repassaNoMesmoMesDeVencimento: this.proposta.repassaNoMesmoMesDeVencimento ?? false,
          }
          await useJwt
            .post('proposta/calcularProposta', parametros)
            .then((response) => {
              this.calcularValorMaisProximoParcela(response.data, valorDaParcela)
            })
            .catch((error) => {
              this.msgError('Erro ao calcular parcela')
              this.calculandoParcela = false
              console.error(error)
            })
        } catch (error) {
          this.msgError('Erro ao calcular parcela')
          this.calculandoParcela = false
          console.error(error)
        }
      },
      async recalcular() {
        let iof
        let float

        if (this.utilizaIOF) {
          iof = await this.calcularIOF()
          this.proposta.valorComIOF = formatarValorReais(iof.valorComIOF)
          this.proposta.parcelaComIOF = formatarValorReais(iof.parcelaComIOF)
          this.proposta.valorPrimeiraParcela = formatarParaNumber(iof.parcelaComIOF)
          this.proposta.valorIOF = formatarValorReais(iof.valorIOF)
          this.proposta.taxaIOF = iof.taxaIOF
          this.proposta.quantidadeDiasIOF = iof.quantidadeDiasIOF
          this.proposta.valorBaseCalculo = iof.valorBaseCalculo
          this.proposta.valorDiarioIOF = iof.valorDiarioIOF
          this.proposta.valorEfetivoIOF = iof.valorEfetivoIOF
          this.proposta.taxaDiariaIOF = iof.taxaDiariaIOF
          this.proposta.taxaEfetivaIOF = iof.taxaEfetivaIOF
          this.proposta.valorTotalIOF = iof.valorTotalIOF
        }

        if (this.utilizaFloatFinanceiro) {
          float = await this.calcularFloat()
          this.proposta.valorComFloat = formatarValorReais(float.valorComFloat)
          this.proposta.valorPrimeiraParcela = formatarParaNumber(float.parcelaComFloat)
          this.proposta.valorDoFloat = formatarValorReais(float.valorDoFloat)
        }

        let valorTotal = formatarParaNumber(this.proposta.valor)

        if (iof) valorTotal += iof.valorIOF
        if (float) valorTotal += float.valorDoFloat

        let valorPrimeiraParcela = this.proposta.valorPrimeiraParcela

        this.proposta.valorPrimeiraParcela = formatarValor(valorPrimeiraParcela)

        this.proposta.valorTotal = formatarValor(valorTotal)
      },
      async gerarParcelas() {
        this.isBusy = true
        let saldoDevedor = this.utilizaIOF
          ? formatarParaNumber(this.proposta.valorComIOF)
          : formatarParaNumber(this.proposta.valor)

        saldoDevedor = this.utilizaFloatFinanceiro ? formatarParaNumber(this.proposta.valorComFloat) : saldoDevedor

        const valorFloat = this.utilizaFloatFinanceiro ? formatarParaNumber(this.proposta.valorDoFloat) : 0

        // comment to commit
        const parcelasCalculadas = await useJwt
          .post('utils/GetcalcularParcelasSegurado', {
            idSegurado: this.proposta.seguradoSelecionado.id,
            institutoSelecionado: this.proposta.institutoSelecionado,
            parcelas: this.proposta.parcelas,
            taxa: this.proposta.taxa.toString(),
            taxaSpreadPosFixado: this.userData.Instituto.taxaSpreadPosFixado,
            saldoDevedor: saldoDevedor,
            valorFloat: valorFloat,
            valorDaParcela: formatarParaNumber(this.proposta.valorPrimeiraParcela),
            tipoCalculo: this.proposta.tipoCalculo,
            formaCalculo: this.proposta.formaCalculo,
          })
          .then((response) => {
            this.itemsParcelas = response.data
            this.isBusy = false
            return response.data
          })
          .catch((error) => {
            this.msgError('Erro ao gerar parcelas!')
            console.error(error)
          })

        for (const parcela of this.itemsParcelas) {
          if (parcela.taxaindiceInflacionarioPosFixado?.value) {
            parcela.taxaindiceInflacionarioPosFixado = parcela.taxaindiceInflacionarioPosFixado?.value
          }
          if (parcela.taxajurosAtuariaisPosFixado?.value) {
            parcela.taxajurosAtuariaisPosFixado = parcela.taxajurosAtuariaisPosFixado?.value
          }
          if (parcela.taxafundoGarantidorPosFixado?.value) {
            parcela.taxafundoGarantidorPosFixado = parcela.taxafundoGarantidorPosFixado?.value
          }
          if (parcela.taxataxaAdministrativaPosFixado?.value) {
            parcela.taxataxaAdministrativaPosFixado = parcela.taxataxaAdministrativaPosFixado?.value
          }

          parcela.valor = Number(parcela.valor).toFixed(2)
        }

        const initialValue = 0
        this.valorTotalParcelas = this.itemsParcelas.reduce(
          (accumulator, currentValue) => Number(accumulator) + Number(currentValue.valor),
          initialValue,
        )
        this.valorTotalParcelas = formatarValor(formatarParaNumber(this.valorTotalParcelas).toFixed(2))
        this.totalRowsParcelas = parcelasCalculadas.length

        return {
          valorTotalParcelas: this.valorTotalParcelas,
          itemsParcelas: this.itemsParcelas,
          primeiraParcela: this?.itemsParcelas[0]?.valor,
        }
      },
      async calcularValorMaisProximoParcela(resposta, valorDaParcela) {
        const dados = this.findClosestValue(resposta, valorDaParcela)

        this.proposta.valorPrimeiraParcela = dados.parcelaReal
        this.proposta.valorBaseCalculo = dados.valor

        const { iof } = dados
        const { float } = dados

        if (this.utilizaIOF) {
          this.proposta.valorComIOF = formatarValorReais(iof.valorComIOF)
          this.proposta.parcelaComIOF = formatarValorReais(iof.parcelaComIOF)
          this.proposta.valorIOF = formatarValorReais(iof.iofDiario + iof.iofEfetivo)
          this.proposta.taxaIOF = iof.taxaIOF
        }

        if (this.utilizaFloatFinanceiro) {
          this.proposta.valorComFloat = formatarValorReais(float.float + iof.valorComIOF)
          this.proposta.valorDoFloat = formatarValorReais(float.float)
        }

        this.proposta.valor = formatarValor(dados.valor)
        this.proposta.valorTotal = formatarValor(dados.valorTotal)
        this.proposta.valorPrimeiraParcela = formatarValor(dados.parcelaReal)
      },
      adicionarMascaraCPF(value) {
        value = value.replace(/\D/g, '')
        if (value.length > 12) {
          value = value.slice(0, 12)
        }
        if (value.length > 3) {
          value = value.slice(0, 3) + '.' + value.slice(3)
        }
        if (value.length > 7) {
          value = value.slice(0, 7) + '.' + value.slice(7)
        }
        if (value.length > 11) {
          value = value.slice(0, 11) + '-' + value.slice(11, 13)
        }
        return value
      },
      fetchResults() {
        if (this.tipoBusca == 2) {
          this.proposta.segurado = this.adicionarMascaraCPF(this.proposta.segurado)
          const cpfSemMascara = this.proposta.segurado.replace(/\D/g, '')
          if (cpfSemMascara.length === 11) {
            const cpfValido = isCPFValid(cpfSemMascara)
            if (!cpfValido) {
              this.$message.error('Digite um cpf válido')
              return
            }
          }
        }
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          if (!this.proposta.segurado) {
            this.proposta = {
              ...this.proposta,
              seguradoSelecionado: null,
              segurado: null,
            }
            return
          }
          // let institutoId = this.userData.Instituto[0].id ?? this.proposta.institutoSelecionado;
          if (this.proposta.segurado.length >= 3) {
            useJwt
              .pesquisar('cadastro/tomador/GetSimulacaoPropostaSegurados', {
                descricao: this.proposta.segurado,
                institutoSelecionado: this.proposta.institutoSelecionado,
                tipoBusca: this.tipoBusca,
              })
              .then((response) => {
                if (response.data.length == 0) this.$message.error('Não foram encontrados tomadores!')
                this.suggestions = [{ data: response.data }]
              })
              .catch((error) => {
                console.error(error)
              })
          } else this.suggestions = []
        }, this.debounceMilliseconds)
      },
      async buscarTipoDeCalculo() {
        await useJwt
          .get(`cadastro/instituto/buscarTipoDeCalculo/${this.proposta.institutoSelecionado}`)
          .then((response) => {
            const opcoes = [{ value: null, text: 'Selecione:', disabled: true }] // Opção de placeholder
            let quantidadeDeOpcoes = 0

            if (response.data[0].tipoCalculoSAC == true) {
              opcoes.push({ id: 'tipoCalculoSac', value: 'sac', text: 'SAC' })

              quantidadeDeOpcoes++
            }
            if (response.data[0].tipoCalculoPrice == true) {
              opcoes.push({ id: 'tipoCalculoPrice', value: 'price', text: 'PRICE' })
              quantidadeDeOpcoes++
            }
            if (response.data[0].tipoCalculoPosFixado == true) {
              opcoes.push({ id: 'tipoCalculoPosFixado', value: 'posFixado', text: 'Pós Fixado' })
              quantidadeDeOpcoes++
            }

            if (quantidadeDeOpcoes == 1) {
              this.proposta.tipoCalculo = opcoes[1].value
            }
            this.statusTipoCalculo = opcoes
          })
          .catch((error) => {
            console.error(error)
          })
      },
      async GetPesquisarIdadeCriteriosElegibilidade(item) {
        const dados = {
          institutoId: this.userData.institutoSelecionado,
          categoria: this.segurado.categoria,
          idade: calcularIdade(item.tomador.dataNascimento),
        }
        await useJwt
          .post('cadastro/instituto/GetPesquisarIdadeCriteriosElegibilidade', dados)
          .then((response) => {
            this.idadePermitida = response.data?.length > 0
          })
          .catch((error) => {
            console.error(error)
          })
      },
      async getSuggestionValue(suggestion) {
        const { item } = suggestion
        this.isBusySegurado = true
        if (item.diasDeCarenciaBloqueado) {
          this.proposta.segurado = ''
          this.suggestions = []

          this.isBusySegurado = false
          this.$message.error('Bloqueado por período de carência')
          return
        }

        if (!item.grupoFolhaId) {
          this.proposta.segurado = ''
          this.suggestions = []

          this.$swal({
            title: 'Atenção!',
            text: 'O segurado não possui grupo folha cadastrado.',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })

          this.isBusySegurado = false
          return
        }

        await this.GetPesquisarIdadeCriteriosElegibilidade(item)

        if (!this.idadePermitida) {
          this.proposta.segurado = ''
          this.suggestions = []
          this.$swal({
            title: 'Atenção!',
            text: 'O segurado não possui idade permitida para o empréstimo.',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })

          this.isBusySegurado = false
          return
        }

        if (!item.grupoFolha.diaDeRepasse) {
          this.proposta.segurado = ''
          this.suggestions = []

          this.$swal({
            title: 'Atenção!',
            text: 'O grupo folha não possui dia de repasse cadastrado.',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })

          this.isBusySegurado = false
          return
        }

        if (!this.segurado.tomador.dataNascimento) this.campoSeguradoSelecionado = false
        this.exibeDadosSegurado = true
        this.segurado.id = item.id
        this.segurado.tomador.cpf = item.tomador.cpf
        this.segurado.tomador.nomeCompleto = item.tomador.nomeCompleto
        this.segurado.tomador.nomeSocial = item.tomador.nomeSocial
        this.segurado.tomador.dataNascimento = formatarData(item.tomador.dataNascimento)
        this.segurado.categoria = item.situacaoPrevidenciaria
        this.segurado.remuneracaoProventos = formatarValor(item.remuneracao)
        this.segurado.margemUtilizada = formatarValor(item.margemUtilizada)
        this.segurado.margemDisponivel = formatarValor(item.margemDisponivel)
        this.proposta.seguradoSelecionado = item
        this.proposta.diaDeRepasse = item.grupoFolha.diaDeRepasse
        this.proposta.diaFechamentoFolha = item.grupoFolha.diaFechamentoFolha
        this.proposta.repassaNoMesmoMesDeVencimento = item.grupoFolha.repassaNoMesmoMesDeVencimento ?? false

        this.isBusySegurado = false

        return item.nomeCompleto
      },
      validationDadosSegurado() {
        return new Promise(async (resolve, reject) => {
          await this.$refs.tomRules.validate().then(async (success) => {
            if (success && this.segurado?.tomador?.nomeCompleto) {
              resolve(true)
            } else {
              let title
              if (!success) title = 'Alerta! Há campos não preenchidos no formulário.'
              else title = 'Alerta! Selecione o segurado na pesquisa para avançar.'
              this.msgError(title)
              reject()
            }
          })
        })
      },
      validationTipoCalculo() {
        return new Promise(async (resolve, reject) => {
          await this.$refs.tipRules.validate().then((success) => {
            if (success) {
              // let institutoId = this.userData.Instituto[0].id ?? this.proposta.institutoSelecionado;

              useJwt
                .post('utils/GetcalcularValoresSegurado', {
                  idSegurado: this.segurado.id,
                  institutoSelecionado: this.proposta.institutoSelecionado,
                  tipoCalculo: this.proposta.tipoCalculo,
                  formaCalculo: this.proposta.formaCalculo,
                })
                .then((response) => {
                  const values = {
                    ...response.data,
                    valor: undefined,
                    valorPrimeiraParcela: undefined,
                    parcelas: undefined,
                  }
                  this.proposta = {
                    ...this.proposta,
                    ...values,
                  }
                })
                .catch((error) => {
                  console.error(error)
                  this.$swal({
                    title: 'Atenção!',
                    text: 'Segurado não elegível para o empréstimo.',
                    icon: 'error',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                })
              this.proposta.valorPrimeiraParcela = undefined
              this.proposta.valor = undefined
              this.proposta.parcelas = undefined
              this.proposta.valorIOF = undefined
              this.proposta.valorTotal = undefined
              this.proposta.valorDaDivida = undefined
              this.proposta.bancoId = undefined
              this.$refs.dadosRules.reset()
              resolve(true)
            } else {
              const title = 'Alerta: Há campos não preenchidos no formulário.'
              this.msgError(title)
              reject()
            }
          })
        })
      },
      validationDadosConsignacao() {
        return new Promise(async (resolve, reject) => {
          await this.$refs.dadosRules.validate().then(async (success) => {
            if (success && this.proposta.valor && this.proposta.parcelas && this.proposta.valorPrimeiraParcela) {
              await this.gerarParcelas()
              resolve(true)
            } else {
              const valorDigitado = this.proposta.valorPrimeiraParcela
              const valorMaximo = this.proposta.valorMaximoPrimeiraParcela
              const valorDigitadoFloat = parseFloat(valorDigitado?.replace(/\./g, '')?.replace(',', '.'))
              if (valorDigitadoFloat > valorMaximo) {
                const title = 'Alerta: O valor da parcela é maior que o permitido.'
                this.msgError(title)
                reject()
              } else if (!success && (!this.proposta.valorPrimeiraParcela || !this.proposta.valor || !this.proposta.parcelas)) {
                this.msgError('Erro: Há campos não preenchidos no formulário.')
                reject()
              } else if (
                !success &&
                formatarParaNumber(this.proposta.valor) < formatarParaNumber(this.parametroValorMinimoEmprestimo)
              ) {
                this.msgError('Existem valores inferiores ao parâmetro mínimo.')
                reject()
              } else if (
                !success &&
                formatarParaNumber(this.proposta.valorPrimeiraParcela) < formatarParaNumber(this.parametroValorMinimoParcela)
              ) {
                this.msgError('Existem valores inferiores ao parâmetro mínimo.')
                reject()
              } else if (!success && !this.proposta.valorDaDivida) {
                this.msgError('Campo valor da dívida é obrigatório.')
                reject()
              } else if (!success && !this.proposta.bancoId) {
                this.msgError('Campo banco é obrigatório.')
                reject()
              } else if (
                !success &&
                formatarParaNumber(this.proposta.valorDaDivida) > formatarParaNumber(this.proposta.valor)
              ) {
                this.msgError('Existem valores maiores ao parâmetro máximo.')
                reject()
              }
            }
          })
        })
      },
      validationTabelaParcelas() {
        return new Promise(async (resolve, reject) => {
          await this.$refs.bankRules.validate().then((success) => {
            if (success) {
              resolve(true)
            } else {
              const title = 'Erro: Há campos não preenchidos no formulário.'
              this.msgError(title)
              reject()
            }
          })
        })
      },

      msgError(Text) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Atenção!',
              text: Text,
              icon: 'BellIcon',
              variant: 'danger',
            },
          },
          {
            position: 'top-right',
            timeout: 8000,
          },
        )
      },
      msgSuccess(Title) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: Title,
              icon: 'UserCheckIcon',
              variant: 'success',
            },
          },
          {
            position: 'top-right',
            timeout: 8000,
          },
        )
      },
      async salvarSimulacao() {
        const { proposta } = this
        await this.buscaCompetenciaTaxa()
        const navegadorUtilizado = detectarNavegador()
        this.encontrarNomeBanco(proposta.bancoId)

        if (!proposta.status) {
          const dataToSave = {
            ...proposta,
            valor: formatarParaNumber(proposta.valor),
            valorPrimeiraParcela: this.itemsParcelas[0].valor,
            primeiraParcela: proposta.primeiraParcela.split('/').reverse().join('-'),
            taxa: Number(math.multiply(Number(proposta.taxa), 100)),
            taxaDaCompetencia: this.taxaDaCompetencia,
            competenciaDaTaxa: this.competenciaDaTaxa,
            parcelas: parseInt(proposta.parcelas),
            seguradoId: proposta.seguradoSelecionado.id,
            institutoId: proposta.institutoSelecionado,
            navegadorUtilizado,
            tipoDeCalculoUtilizado: proposta.tipoCalculo,
            indiceUtilizado: this.userData.Instituto.tipoIndiceSelecionado,
            itemsParcelas: this.itemsParcelas,
            origem: 'Administração',
          }

          if (this.userData.vendedor) {
            dataToSave.usuarioId = this.userData.id
          }

          if (proposta.compraDeDivida) {
            dataToSave.compraDeDivida = proposta.compraDeDivida
            dataToSave.valorDivida = proposta.valorDaDivida
            dataToSave.valorRemanescente = proposta.valorRemanescente
            dataToSave.nomeBanco = proposta.nomeBanco
            dataToSave.parcelasQuitadas = proposta.parcelasQuitadas ? Number(proposta.parcelasQuitadas) : 0
          }

          if (this.utilizaIOF) {
            dataToSave.valor = proposta.valorComIOF
          }

          if (this.utilizaFloatFinanceiro) {
            dataToSave.valor = proposta.valorComFloat
          }

          let { segurado, seguradoSelecionado, ...data } = { ...dataToSave }

          if (proposta.formaCalculo == 'valorDaParcela') {
            data = {
              ...data,
              valor: formatarParaNumber(data.valor),
              valorComFloat: formatarParaNumber(data.valorComFloat),
              valorComIOF: formatarParaNumber(data.valorComIOF),
              valorIOF: formatarParaNumber(data.valorIOF),
              valorTotal: formatarParaNumber(data.valorTotal),
              parcelaComFloat: data.parcelaComFloat,
              // parcelaComIOF: formatarParaNumber(data.parcelaComIOF),
              parcelaComIOF: data.parcelaComIOF,
              valorPrimeiraParcela: Number(data.valorPrimeiraParcela),
              valorFloat: formatarParaNumber(data.valorDoFloat),
              valorDoEmprestimo: Number(data.valorDoEmprestimo),
            }
          } else {
            data = {
              ...data,
              valor: formatarParaNumber(data.valor),
              valorComFloat: formatarParaNumber(data.valorComFloat),
              valorComIOF: formatarParaNumber(data.valorComIOF),
              valorIOF: formatarParaNumber(data.valorIOF),
              valorTotal: formatarParaNumber(data.valorTotal),
              parcelaComFloat: formatarParaNumber(data.parcelaComFloat),
              valorDoEmprestimo: Number(data.valorDoEmprestimo),
              valorFloat: formatarParaNumber(this.proposta.valorDoFloat),
            }
          }

          if (this.proposta.tipoCalculo != 'posFixado') data.taxa = Number(this.userData.Instituto.taxaEmprestimoMensal)
          else data.taxa = Number(this.userData.Instituto.taxaSpreadPosFixado)

          useJwt
            .post('proposta/novaProposta', data)
            .then((response) => {
              if (response.data.status == 'erro') {
                console.error(response.data.mensagem)
                this.$swal({
                  title: 'Atenção!',
                  text: response.data.mensagem,
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                }).then((result) => {
                  if (result.value) {
                    this.loading = !this.loading
                    this.$emit('atualizarGrid')
                    this.$emit('closeSimulationModal')
                  }
                })
                return
              }

              this.$swal({
                title: 'Atenção!',
                text: 'Proposta gerada com sucesso',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              }).then((result) => {
                this.loading = !this.loading
                this.$emit('atualizarGrid')
                this.$emit('closeSimulationModal')
              })
            })
            .catch((error) => {
              this.loading = false
              console.error(error)
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: error.response.data.error,
                    icon: 'BellIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'top-right',
                  timeout: 8000,
                },
              )
            })
        } else {
          this.loading = false
          this.$swal({
            title: 'Atenção!',
            text: proposta.error,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
      },
      async buscaCompetenciaTaxa() {
        return new Promise(async (resolve, reject) => {
          await useJwt
            .get(`configuracoes/indices/FindOne/${this.userData.Instituto.tipoIndiceSelecionado}`)
            .then((response) => {
              this.competenciaDaTaxa = response.data.competencia
              this.taxaDaCompetencia = response.data.taxa
              resolve()
            })
            .catch((error) => {
              console.error(error)
              this.$message.error('Houve um erro ao consultar a taxa da competência.')
              reject()
            })
        })
      },
      async calcularIOF() {
        const parametros = {
          ...this.proposta,
          valor: formatarParaNumber(this.proposta.valor),
          valorDoEmprestimo: formatarParaNumber(this.proposta.valor),
          taxaDiariaIOF: this.userData.Instituto.aliquotaDiariaIOF,
          taxaEfetivaIOF: this.userData.Instituto.aliquotaEfetivaIOF,
          taxaSpreadPosFixado: this.userData.Instituto.taxaSpreadPosFixado,
        }

        const response = await useJwt.post('proposta/GetCalcularIOF', parametros)
        console.log('IOF: ', response.data)

        const calculoIOf = {
          valorComIOF: response.data.valorComIOF,
          parcelaComIOF: response.data.parcelaComIOF,
          valorIOF: response.data.valorIOF,
          taxaIOF: response.data.taxaIOF,

          quantidadeDiasIOF: response.data.quantidadeDias,
          valorBaseCalculo: response.data.valorBaseCalculo,
          valorDiarioIOF: response.data.valorDiarioIOF,
          valorEfetivoIOF: response.data.valorEfetivoIOF,
          taxaDiariaIOF: response.data.taxaDiariaIOF,
          taxaEfetivaIOF: response.data.taxaEfetivaIOF,
          valorTotalIOF: response.data.valorTotalIOF,
        }
        return calculoIOf
      },
      async calcularFloat() {
        const parametroFloat = {
          valor: this.utilizaIOF ? formatarParaNumber(this.proposta.valorComIOF) : formatarParaNumber(this.proposta.valor),
          parcelas: formatarParaNumber(this.proposta.parcelas),
          taxa: this.proposta.taxa,
          diaDeRepasse: this.proposta.diaDeRepasse,
          diaFechamentoFolha: this.proposta.diaFechamentoFolha,
          repassaNoMesmoMesDeVencimento: this.proposta.repassaNoMesmoMesDeVencimento ?? false,
          instituto: {
            tipoVencimentoParcela: this.userData.Instituto.tipoVencimentoParcela,
            diaFixoVencimentoParcela: this.userData.Instituto.diaFixoVencimentoParcela,
            mesVencimento: this.userData.Instituto.mesVencimento,
            taxaEmprestimoMensal: this.userData.Instituto.taxaEmprestimoMensal,
            taxaSpreadPosFixado: this.userData.Instituto.taxaSpreadPosFixado,
          },
          tipoCalculo: this.proposta.tipoCalculo,
          taxaSpreadPosFixado: this.userData.Instituto.taxaSpreadPosFixado,
        }

        const response = await useJwt.post('proposta/GetCalcularFloat', parametroFloat)
        console.log('FLOAT: ', response.data)

        const valoresFloat = {
          valorComFloat: response.data.valorComFloat,
          valorDoFloat: response.data.valorDoFloat,
          parcelaComFloat: response.data.parcelaComFloat,
        }

        return valoresFloat
      },
      formatarValorToText(valor) {
        let valorString = String(valor)
        if (!valorString.includes('.')) {
          valorString = `${valorString}.00`
        }
        return valorString
      },
      preparaComponenteParaAplicativo() {
        this.proposta = {}
        const nomeTomador = String(this.userData.nomeCompleto).trim()

        // Consulta dados do tomador logado
        useJwt
          .pesquisar('cadastro/tomador/GetSimulacaoPropostaSegurados', {
            descricao: nomeTomador,
            institutoSelecionado: this.userData.institutoSelecionado,
          })
          .then((response) => {
            this.segurado.id = response.data[0].id
            this.segurado.tomador.cpf = response.data[0].tomador.cpf
            this.segurado.tomador.nomeCompleto = response.data[0].tomador.nomeCompleto
            this.segurado.tomador.nomeSocial = response.data[0].tomador.nomeSocial
            this.segurado.tomador.dataNascimento = formatarData(response.data[0].tomador.dataNascimento)
            this.segurado.categoria = response.data[0].situacaoPrevidenciaria
            this.segurado.remuneracaoProventos = formatarValor(response.data[0].remuneracao)
            this.segurado.margemUtilizada = formatarValor(response.data[0].margemUtilizada)
            this.segurado.margemDisponivel = formatarValor(response.data[0].margemDisponivel)
            this.proposta.seguradoSelecionado = response.data[0]
            this.proposta.diaDeRepasse = response.data[0].grupoFolha.diaDeRepasse
            this.proposta.diaFechamentoFolha = response.data[0].grupoFolha.diaFechamentoFolha
          })
          .catch((error) => {
            console.error(error)
          })

        // Configura botões de ação
      },
      async validarBotaoCalculo() {
        if (this.proposta.formaCalculo === 'valorDoEmprestimo') {
          const validValor = await this.$refs.valorProposta.validate()
          const validParcelas = await this.$refs.qtdParcelas.validate()

          if (validValor.failedRules.required || validParcelas.failedRules.required) {
            this.$message.error('Preencha todos os campos obrigatórios')
            return
          }
        } else if (this.proposta.formaCalculo === 'valorDaParcela') {
          const validParcelas = await this.$refs.qtdParcelas.validate()
          const validValorParcela = await this.$refs.valorParcela.validate()

          if (validParcelas.failedRules.required || validValorParcela.failedRules.required) {
            this.$message.error('Preencha todos os campos obrigatórios')
            return
          }
        }

        await this.calcularProposta()
      },

      limparCampoSegurado() {
        this.proposta.segurado = ''
      },

      limparCampos(aba) {
        if (aba == 2) {
          this.proposta = {
            ...this.proposta,
            valorComIOF: null,
            parcelaComIOF: null,
            valorIOF: null,
            taxaIOF: null,
            valorDaDivida: null,
            valorTotal: undefined,
            valorRemanescente: 0,
            parcelasQuitadas: null,
          }
        }
      },
    },
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-autosuggest.scss';
  @import '@core/scss/vue/libs/vue-select.scss';

  pre {
    min-height: 295px;
    padding: 1.5rem;
    margin-bottom: 0;
    border-radius: 0.5rem;
  }

  .autosuggest__results-item {
    cursor: pointer;
  }

  #radiosFormasDeCalculo,
  #radiosTipoDeCalculo {
    display: flex;
  }

  #radiosFormasDeCalculo label span {
    white-space: nowrap;
  }
</style>
